<template>
    <div>
        <div v-if="viewno == 1">
            <div class="col-md-12">
                <div class="row" style="margin-top: 20px">
                    <div class="col-md-10">
                        <h3 class="page-header">
                            <i class="fa fa fa-list animated bounceInDown show-info"></i>
                            Purchase Stock
                        </h3>
                    </div>
                    <div class="col-md-2 flex-center-row">
                        <button @click="refresh()" class="btn btn-primary">
                            <i class="fa fa-refresh"></i> Refresh
                        </button>
                    </div>
                </div>
            </div>
            <div class="panel panel-cascade">
                <div class="panel-body">
                    <div class="form-horizontal cascde-forms">
                        <div class="row">
                            <div class="col-md-4">
                                <label><strong>From Date</strong></label>
                                <input type="date" class="form-control form-cascade-control input-small"
                                    v-model="fromdate">
                            </div>
                            <div class="col-md-4">
                                <label><strong>To Date</strong></label>
                                <input type="date" class="form-control form-cascade-control input-small"
                                    v-model="todate">
                            </div>
                            <div class="col-md-4">
                                <label><strong>Invoice no.</strong></label>
                                <input type="text" class="form-control form-cascade-control input-small"
                                    v-model="searchinvoiceno">
                            </div>
                        </div>
                        <div class="row mt-10">
                            <div class="col-md-4">
                                <label><strong>Party</strong></label>
                                <!-- <input type="text" class="form-control form-cascade-control input-small"
                                                v-model="product"> -->

                                <select class="form-control form-cascade-control input-small .dropdown-menu"
                                    v-model="customerid">
                                    <option value="">All</option>
                                    <option v-for="item in parties" :key="item.id" :value="item.id">{{
                                        item.firmname }}
                                    </option>
                                </select>
                            </div>
                            <div class="col-md-4 flex-around-row ">
                                <button @click="refresh()" class="btn bg-success text-white" style="margin-top:24px;"
                                    type="button">Search</button>
                                <button @click="cancel()" class="btn bg-danger text-white" style="margin-top:24px;"
                                    type="button">Cancel</button>
                            </div>
                            <!-- <div class="col-md-4">
                                <label><strong>Sale By Id</strong></label>
                                <div style="position: relative;">
                                    <input type="text" class="form-control form-cascade-control input-small"
                                        @input="referralSearch()" v-model="generatedid">
                                    <span class="suffix" v-if="referraluser != null">{{ referraluser.name
                                        }}</span>
                                </div>
                            </div>
                            <div class="col-md-4">
                                <label><strong>Customer Name</strong></label>
                                <input type="text" class="form-control form-cascade-control input-small"
                                    v-model="customername">
                            </div> -->

                        </div>
                    </div>
                </div>
            </div>
            <div class="col-md-12">
                <div class="row">
                    <div class="col-md-4">
                        <div class="total-div">
                            <label>No. of Purchase :</label>
                            <span class="order-val" style="background: blueviolet;">{{ this.allpurchasecount }}</span>
                        </div>
                    </div>
                    <div class="col-md-4">
                        <div class="total-div">
                            <label>Total Purchase value :</label>
                            <span class="order-val" style="background: dodgerblue;">{{ totalpurchasevalue }}</span>
                        </div>
                    </div>
                </div>
            </div>
            <div class="col-md-12 row mt-20">
                <div class="col-md-3">
                    <div class="row">
                        <div class="col-md-6">
                            <strong>Show Rec.</strong>
                        </div>
                        <div class="col-md-6">
                            <select class="form-control col-mb-1" v-model="noofrec" id="rec" @change="refresh()">
                                <option value=10>10</option>
                                <option value=25>25</option>
                                <option value=50>50</option>
                                <option value=100>100</option>
                                <option value=500>500</option>
                                <option value=1000>1000</option>
                            </select>
                        </div>
                    </div>
                </div>
            </div>
            <div class="col-md-12 table-responsive" style="padding:0px;">
                <div class="panel">
                    <div class="panel-body" style="overflow-x:auto;padding-left:0px">
                        <table class="table table-bordered table-hover table-striped display">
                            <thead class="table-font">
                                <tr>
                                    <th>#</th>
                                    <th>Party Name</th>
                                    <th>Invoice No.</th>
                                    <th>Purchase No.</th>
                                    <th>Purchase Date</th>
                                    <th>Invoice Value</th>
                                    <th>Entry By</th>
                                    <th>Entry Date</th>
                                    <th class="text-center">Details</th>
                                    <th class="text-center" style="width: 17%;">Action</th>
                                    <!-- <th class="text-center">Delete</th>
                                    <th class="text-center">Print</th> -->
                                </tr>
                            </thead>
                            <tbody class="table-font" v-for="(item, index) in this.transaction" :key="item.id">
                                <tr>
                                    <td>{{ (currentpage - 1) * noofrec + (index + 1) }} </td>
                                    <td>{{ item.user == null ? '' : item.user.firmname }}</td>
                                    <td>{{ item.invoiceid }}</td>
                                    <td>{{ item.usersequence }}</td>
                                    <!-- <td>{{ moment(item.created_at).format("DD-MM-YYYY") }}</td> -->
                                    <td>{{ item.invoicedate }}</td>
                                    <td>{{ item.amountwithtax }}</td>
                                    <td>{{ item.enteredby.name }}</td>
                                    <td>{{ item.transactiondate }}</td>
                                    <td class="text-center"><button class="btn bg-success text-white btn-xs btnsearch"
                                            @click="transactiondetailsmodal(item)">Details</button></td>
                                    <td class="text-center" style="width: 17%;">
                                        <button class="btn btn-primary-edit" @click="transactionedit(item)"><i
                                                class="fa fa-edit"></i></button>
                                        <button class="btn btn-primary-delete" @click="deleteitem(item)"><i
                                                class="fa fa-trash"></i></button>
                                        <button class="btn btn-primary-print" @click="printtransaction(item)"><i
                                                class="fa fa-print"></i></button>
                                    </td>
                                    <!-- <td class="text-center"></td>
                                    <td class="text-center"></td> -->
                                </tr>
                                <tr v-if="check(item) && hideRow">
                                    <td colspan="11">
                                        <table class="table table-bordered table-font"
                                            style="margin-bottom:0;color:brown;font-family:auto!important;font-size:14px!important;">
                                            <thead>
                                                <tr>
                                                    <th>#</th>
                                                    <th>Product Name</th>
                                                    <th>Model No</th>
                                                    <th>Serial no./IMEI</th>
                                                    <th>Quantity</th>
                                                    <th>Price/pcs</th>
                                                    <!-- <th>Total amount</th> -->
                                                </tr>
                                            </thead>
                                            <tbody>
                                                <tr v-for="(data, index) in details" :key="data.id">
                                                    <td scope="row">{{ index + 1 }}</td>
                                                    <td>{{ data.product?.category?.name }}</td>
                                                    <td>{{ data.product.name }}</td>
                                                    <td>{{ data.barcode }}<br v-if="data.barcode">{{ data.colour }}<br
                                                            v-if="data.colour">{{ data.product.name }}</td>
                                                    <td>{{ data.quantity }} </td>
                                                    <td>{{ getProductPrice(data) }}</td>
                                                    <!-- <td>{{ data.finalamount }}</td> -->
                                                </tr>
                                            </tbody>

                                        </table>
                                        <div class=" col-md-12 flex-end-row" style="height: 40px;">
                                            <div class="col-md-2 text-end " style="font-weight: 700;">Total Amount :
                                            </div>
                                            <div class="col-md-1" style="font-weight: 700;"> {{ getPurchaseitemTotal()
                                                }}
                                            </div>
                                        </div>
                                    </td>
                                </tr>
                            </tbody>
                        </table>
                    </div>
                </div>
            </div>
            <div class="col-md-12" style="margin-top: 20px">
                <nav aria-label="Page navigation example" style="text-align: right !important">
                    <paginate style="margin: 0px !important; float: right" :page-count="noofpages"
                        :click-handler="clickCallback" :prev-text="'&laquo;'" :next-text="'&raquo;'"
                        :container-class="'pagination'">
                    </paginate>
                </nav>
            </div>
        </div>
        <purchase-form v-if="viewno == 2"></purchase-form>
        <invoice v-if="viewno == 3"></invoice>
    </div>
</template>
<script>
import moment from 'moment';
import { mapGetters } from 'vuex';
import Constants from '../../components/utilities/Constants.vue';
import PurchaseForm from './PurchaseForm.vue';
import Invoice from '../invoice/Invoice.vue';
import Paginate from "vuejs-paginate";
export default {
    mixins: [Constants],
    components: { PurchaseForm, Invoice, Paginate },
    data() {
        return {
            fromdate: '',
            todate: '',
            searchinvoiceno: '',
            details: [],
            transaction: [],
            moment: moment,
            id: 0,
            hideRow: false,
            allpurchasecount: 0,
            noofrec: 10,
            customerid: 0,
            totalpurchasevalue: 0
        }
    },
    mounted() {
        this.refresh()
        this.$store.dispatch("fetchparties")
        // this.fetchParty()
        // this.$store.dispatch("")
    },
    computed: {
        ...mapGetters([
            'parties', 'loggedinuser', 'viewno', 'categories', 'currentpage'
        ]),
        noofpages() {
            return Math.ceil(parseInt(this.allpurchasecount) / parseInt(this.noofrec))
        },
    },
    methods: {
        // fetchParty() {
        //     let param = { noofrec: this.noofrec, currentpage: this.currentpage, firmname : this.searchstring }

        // },
        transactiondetailsmodal(item) {
            if (!this.hideRow) {
                this.$store.commit('assignloadingstatus', 1)
                let param = { transactionid: item.id };
                // window.axios.post('api/ptransaction/details',param)
                window.axios.post('api/fetch/barcode/purchase', param)
                    .then(response => this.processDetailResponse(response.data))
                    .catch(error => this.errorapi(error));
            } else {
                this.hideRow = false
            }
        },
        processDetailResponse(data) {
            this.$store.commit('assignloadingstatus', 0)
            // this.details=data.transactiondetails
            // this.id=data.transaction.id
            this.details = data.transaction
            this.id = this.details[0].transactionid
            this.hideRow = !this.hideRow
        },
        refresh() {
            this.$store.commit('assignloadingstatus',1)
            let param = {
                invoicetodate: this.todate, invoicefromdate: this.fromdate, searchinvoiceno: this.searchinvoiceno,
                noofrec: this.noofrec, currentpage: this.currentpage, customerid: this.customerid, fy: this.getCurrentFinancialYear(),
            }
            console.log(param)
            window.axios.post('api/purchasetransactions/datewise', param)
                .then(response => this.processResponse(response.data))
                .catch(error => this.errorapi(error));
            window.axios.post('api/purchasetransaction/datewise/count', param)
                .then(response => this.processResponseCount(response.data))
                .catch(error => this.errorapi(error));
            window.axios.post('api/total/purchasetransaction/value', param)
                .then(response => this.processTotalValueResponse(response.data))
                .catch(error => console.log(error));

        },
        processTotalValueResponse(data) {
            // console.log(data)
            this.totalpurchasevalue = data.totalpurchase
        },
        errorapi() {
            alert('Someting went wrong')
        },
        processResponse(data) {
            this.transaction = data
            // console.log(data)
            this.$store.commit('assignloadingstatus',0)
        },
        processResponseCount(data) {
            // console.log(data)
            this.allpurchasecount = data
        },
        clickCallback(currentpage) {
            // this.currentpage=currentpage
            this.$store.commit("assigncurrentpage", currentpage);
            this.refresh();
        },
        check(item) {
            if (item.id == this.id) {
                return true
            }
        },
        transactionedit(item) {
            // console.log("item",item)
            this.$store.commit('assigntransaction', item)
            this.$store.commit('assignedititem', item)
            this.purchasetransactionedit(item.id)
            this.$store.commit('assignviewno', 2)
        },
        printtransaction(item) {
            let param = { transactionid: item.id };
            window.axios.post('api/ptransaction/details', param)
                .then(response => this.processResponseData(response.data))
                .catch(error => this.errorapi(error));
        },
        processResponseData(data) {
            //  console.log("data", data)
            this.$store.commit("assigntransactiondetails", data.transactiondetails);
            this.$store.commit('assigntransaction', data.transaction);
            //this.$store.commit('assignbarcodeforproduct',data.barcode);
            //this.$router.push({name:"invoice"})
            this.$store.commit('assignviewno', 3);
        },
        back() {
            this.$store.commit('assignviewno', 1);
        },
        getProductPrice(item) {
            // console.log(item)
            let price = 0
            item.purchaseitem.forEach((element) => {
                if (element.productid == item.productid) {
                    price = element.price
                }
            })
            return price
        },
        getPurchaseitemTotal() {
            let temp = 0
            this.details.forEach((element) => {
                if (element.purchaseitem != null) {
                    element.purchaseitem.forEach((value) => {
                        if (value.productid == element.productid) {
                            temp = parseFloat(value?.price) + temp
                        }
                    })
                }
            })
            return temp.toFixed(2);
        },
        deleted() {
            this.refresh()
        }

    },
}
</script>